









































































import { Component, Vue } from 'vue-property-decorator'
import CustomerInfo from '@/components/desktop-pos/customer-info.vue'
import CartItems from '@/components/desktop-pos/cart-items.vue'
import PriceSummary from '@/components/desktop-pos/price-summary.vue'
import MemberCard from '@/components/desktop-pos/member-card.vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import * as onScan from 'onscan.js'
import DesktopPosNavbar from '@/components/desktop-pos/navbar.vue'

@Component({
  components: {
    CustomerInfo,
    CartItems,
    PriceSummary,
    MemberCard,
    DesktopPosNavbar
  },
  computed: {
    ...mapState({
      selectBranch: (state: any) => state.Store.selectBranch,
      selectPos: (state: any) => state.Store.selectPos
    }),
    ...mapGetters({
      calculatedItems: 'Cart/calculatedItems',
      net: 'Cart/net',
      memberTel: 'Cart/tel',
      nationality: 'Cart/nationality',
      gender: 'Cart/gender',
      discount: 'Cart/discount',
      fullPrice: 'Cart/fullPrice',
      totalPrice: 'Cart/totalPrice'
    })
  },
  methods: {
    ...mapActions({
      addToCart: 'Cart/addToCart',
      removeItems: 'Cart/removeItems',
      setTel: 'Cart/setTel',
      setSelectedBranchAddress: 'Store/setSelectedBranchAddress'
    })
  }
})
export default class DesktopCart extends Vue {
  loading = false

  net!: any

  discount!: any

  fullPrice!: any

  totalPrice!: any

  setTel!: any

  selectBranch!: any

  setSelectedBranchAddress!: any

  selectPos!: any

  showFullPriceFormat!: any

  addToCart!: any

  removeItems!: any

  memberTel!: any

  nationality!: any

  gender!: any

  calculatedItems!: any

  private scanner: HTMLElement | null = null

  keyCodeMapperCharacter: any = {
    48: ['0', ')'],
    49: ['1', '!'],
    50: ['2', '@'],
    51: ['3', '#'],
    52: ['4', '$'],
    53: ['5', '%'],
    54: ['6', '^'],
    55: ['7', '&'],
    56: ['8', '*'],
    57: ['9', '('],
    65: ['a', 'A'],
    66: ['b', 'B'],
    67: ['c', 'C'],
    68: ['d', 'D'],
    69: ['e', 'E'],
    70: ['f', 'F'],
    71: ['g', 'G'],
    72: ['h', 'H'],
    73: ['i', 'I'],
    74: ['j', 'J'],
    75: ['k', 'K'],
    76: ['l', 'L'],
    77: ['m', 'M'],
    78: ['n', 'N'],
    79: ['o', 'O'],
    80: ['p', 'P'],
    81: ['q', 'Q'],
    82: ['r', 'R'],
    83: ['s', 'S'],
    84: ['t', 'T'],
    85: ['u', 'U'],
    86: ['v', 'V'],
    87: ['w', 'W'],
    88: ['x', 'X'],
    89: ['y', 'Y'],
    90: ['z', 'Z'],
    186: [';', ':'],
    187: ['=', '+'],
    188: [',', '<'],
    189: ['-', '_'],
    190: ['.', '>'],
    191: ['/', '?'],
    192: ['`', '~'],
    219: ['[', '{'],
    220: ['\\', '|'],
    221: [']', '}'],
    222: ['\'', '"']
  }

  // eslint-disable-next-line class-methods-use-this
  mounted (): void {
    if (this.selectBranch?.warehouse?.id) {
      this.setSelectedBranchAddress(this.selectBranch.warehouse.id)
    }
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    onScan.attachTo(document, {
        suffixKeyCodes: [13], // enter-key expected at the end of a scan
        reactToPaste: true, // Compatibility to built-in scanners in paste-mode (as opposed to keyboard-mode)
        onScan (sCode) { // Alternative to document.addEventListener('scan')
          const patternUrl = /^(https:\/\/www\.gentlewomanonline\.com\/|https:\/\/gentlewomanonline\.com\/|https:\/\/www\.matter-makers\.com\/|https:\/\/matter-makers\.com\/)/
          const patternTel = /^\+\d{1,3}\d{4,14}$/
          if (patternUrl.test(sCode.toLowerCase())) {
            const splitCodeFromUrl = sCode.split('/')
            const code = splitCodeFromUrl[splitCodeFromUrl.length - 1]
            if (code) _this.addItemToCart(code)
          } else if (patternTel.test(sCode)) {
            _this.setTel(sCode)
          }
        },
        keyCodeMapper (oEvent: any) {
          if (_this.keyCodeMapperCharacter[+oEvent.keyCode] !== undefined) {
            return _this.keyCodeMapperCharacter[+oEvent.keyCode][+oEvent.shiftKey]
          }
          return onScan.decodeKeyEvent(oEvent)
        }
    })
  }

  // eslint-disable-next-line class-methods-use-this
  beforeDestroy (): void {
    onScan.detachFrom(document)
  }

  async addItemToCart (code: string): Promise<void> {
    if (this.loading) return

    try {
      this.loading = true
      await this.addToCart(code)
    } catch (error: any) {
      if (error?.message === 'Stock not enough.') {
        this.$buefy.dialog.alert({
          title: 'ของไม่พอขาย',
          message: `${error?.product?.name} <span style='color: darkgreen'>${error?.sku?.color}</span>
          • <span style='color: red'>${error?.sku?.size}</span>
          <span style='font-size: 20px; font-weight: bold;'>หมด!! </span>`,
          confirmText: 'ปิด',
          type: 'is-danger',
          hasIcon: true
        })
      } else if (error?.message === 'This product have reached the purchase limit.') {
        this.$buefy.dialog.alert({
          title: 'ของเกินจำนวนที่จำกัด',
          message: `${error?.product?.name} <span style='color: darkgreen'>${error?.sku?.color}</span>
          • <span style='color: red'>${error?.sku?.size}</span>
          <span style='font-size: 20px; font-weight: bold;'> เกินจำนวนที่จำกัด </span>`,
          confirmText: 'ปิด',
          type: 'is-danger',
          hasIcon: true
        })
      } else {
        this.$buefy.toast.open({ message: error.message, type: 'is-danger' })
      }
    } finally {
      this.loading = false
    }
  }

 async removeCartItem (skuId: string): Promise<void> {
    if (this.loading) return
    this.loading = true
    await this.removeItems(skuId)
    this.loading = false
  }

  get groupedItems (): any[] {
    return this.calculatedItems.reduce((arr: any[], v: any) => {
      const index = arr.findIndex((i: any) => i.id === v.id)

      if (index === -1) {
        return [
          ...arr,
          {
            ...v,
            quantity: 1,
            sumPrice: v.price
          }
        ]
      }

      const tmpArr = [...arr]
      tmpArr[index].quantity += 1
      tmpArr[index].sumPrice += v.price
      return tmpArr
    }, [])
  }

  get sumAmount (): string {
    return this.calculatedItems.length ? `${this.calculatedItems.length}` : '-'
  }
}
